<script>
import LoadingState from 'dashboard/components/widgets/LoadingState.vue';
import router from '../../../../index';
import { useAlert } from 'dashboard/composables';
import VerificationCode from './VerificationCode.vue';
import WhatsappBusinessAPI from 'dashboard/api/whatsappBusiness';

import { loadScript } from 'dashboard/helper/DOMHelpers';
import * as Sentry from '@sentry/vue';

/* global FB */
export default {
  components: {
    LoadingState,
    VerificationCode,
  },
  data() {
    return {
      hasError: false,
      isCreating: false,
      emptyStateMessage: this.$t('INBOX_MGMT.DETAILS.LOADING_FB'),
      errorStateMessage: '',
      errorStateDescription: '',
      hasLoginStarted: false,
      apiKey: '',
      isVerifingCode: false,
      acceptedWhatsappComercialPolice: false,
      acceptedWhatsappBusinessPolice: false,
      selectedNumberId: null,
      phoneNumbers: [],
      phoneNumberItems: [],
      selectedNumber: null,
      showNumberModal: false,
    };
  },
  computed: {
    hasAcceptedPolicies() {
      return (
        this.acceptedWhatsappComercialPolice &&
        this.acceptedWhatsappBusinessPolice
      );
    },
    radioItems() {
      return this.phoneNumbers.map(num => ({
        id: num.id,
        title: `${num.display_phone_number} (${num.code_verification_status})`,
        checked: num.id === this.selectedNumberId,
      }));
    },
    disableConfirm() {
      return !this.selectedNumberId;
    },
  },
  mounted() {
    window.fbAsyncInit = this.runFBInit;
  },

  methods: {
    async startLogin() {
      if (!this.hasAcceptedPolicies) return;

      this.hasLoginStarted = true;
      try {
        // this will load the SDK in a promise, and resolve it when the sdk is loaded
        // in case the SDK is already present, it will resolve immediately
        await this.loadFBsdk();
        this.runFBInit(); // run init anyway, `tryFBlogin` won't wait for `fbAsyncInit` otherwise.
        this.tryFBlogin(); // make an attempt to login
      } catch (error) {
        if (error.name === 'ScriptLoaderError') {
          // if the error was related to script loading, we show a toast
          this.showAlert(this.$t('INBOX_MGMT.DETAILS.ERROR_FB_LOADING'));
        } else {
          // if the error was anything else, we capture it and show a toast
          Sentry.captureException(error);
          this.showAlert(this.$t('INBOX_MGMT.DETAILS.ERROR_FB_AUTH'));
        }
      }
    },

    runFBInit() {
      FB.init({
        appId: window.vordeskConfig.fbAppId,
        xfbml: true,
        version: 'v22.0',
        autoLogAppEvents: true,
        status: true,
      });
      window.fbSDKLoaded = true;
      FB.AppEvents.logPageView();
    },

    async loadFBsdk() {
      return loadScript('https://connect.facebook.net/en_US/sdk.js', {
        id: 'facebook-jssdk',
      });
    },

    tryFBlogin() {
      FB.login(
        response => {
          (async () => {
            this.hasError = false;
            if (response.status === 'connected') {
              this.apiKey = response.authResponse.code;
              await this.fetchWhatsappNumbers();
            } else if (response.status === 'not_authorized') {
              console.error('FACEBOOK AUTH ERROR', response);
              this.hasError = true;
              this.errorStateMessage = this.$t(
                'INBOX_MGMT.DETAILS.ERROR_FB_UNAUTHORIZED'
              );
              this.errorStateDescription = this.$t(
                'INBOX_MGMT.DETAILS.ERROR_FB_UNAUTHORIZED_HELP'
              );
            } else {
              // eslint-disable-next-line no-console
              console.error('FACEBOOK AUTH ERROR', response);
              this.hasError = true;
              // The person is not logged into Facebook, so we're not sure if
              // they are logged into this app or not.
              this.errorStateMessage = this.$t(
                'INBOX_MGMT.DETAILS.ERROR_FB_AUTH'
              );
              this.errorStateDescription = '';
            }
          })();
        },
        {
          config_id: window.vordeskConfig.fbConfigId,
          response_type: 'code',
          override_default_response_type: true,
        }
      );
    },

    async fetchWhatsappNumbers() {
      try {
        const response = await WhatsappBusinessAPI.fetch_phone_numbers(
          this.apiKey
        );
        this.phoneNumbers = response.data.numbers || [];

        if (!this.phoneNumbers.length) {
          this.hasError = true;
          this.errorStateMessage = this.$t(
            'INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.NOT_FOUND'
          );
          return;
        }

        this.phoneNumberItems = this.phoneNumbers.map((num, index) => ({
          id: num.id,
          code_verification_status: num.code_verification_status,
          title: `${num.display_phone_number} - ${num.code_verification_status}`,
          checked: index === 0,
        }));

        if (this.phoneNumberItems.length === 1) {
          this.onRadioSelect({ ...this.phoneNumberItems[0], checked: true });
          this.onConfirmNumber();
        } else {
          this.showNumberModal = true;
        }
      } catch (error) {
        this.hasError = true;
        this.errorStateMessage = this.$t(
          'INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.FETCH_ERROR'
        );
      }
    },

    onRadioSelect(item) {
      this.phoneNumberItems.forEach(el => {
        el.checked = el.id === item.id;
      });
      this.selectedNumber = item;
    },

    onConfirmNumber() {
      this.showNumberModal = false;

      if (!this.selectedNumber) {
        return;
      }

      const status =
        this.selectedNumber.code_verification_status?.toUpperCase();
      if (status === 'VERIFIED') {
        this.createChannel();
      } else {
        this.isVerifingCode = true;
      }
    },

    async createChannel(pin) {
      this.isCreating = true;
      try {
        const config = {
          api_key: this.apiKey,
          phone_number_id: this.selectedNumber?.id,
        };

        if (pin) {
          config.pin = pin;
        }

        const whatsappChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: 'Caixa de Entrada',
            channel: {
              type: 'whatsapp',
              provider: 'whatsapp_signin',
              provider_config: config,
            },
          }
        );

        this.isCreating = false;
        this.isVerifingCode = false;

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: whatsappChannel.id,
          },
        });
      } catch (error) {
        this.isCreating = false;
        this.isVerifingCode = false;
        useAlert(
          error.message || this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE')
        );
      }
    },

    showAlert(msg) {
      useAlert(msg);
    },
  },
};
</script>

<template>
  <div>
    <VerificationCode
      v-if="isVerifingCode"
      :is-creating="isCreating"
      @go-to-next-step="createChannel"
    />

    <div v-if="!hasLoginStarted" class="flex flex-col">
      <div class="mt-4">
        <div class="flex items-center gap-x-4">
          <div style="flex-basis:55%;">
            <h1
              class="text-xl text-slate-800 dark:text-slate-100 mb-6 text-justify"
            >
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.CONFIGURE_BUSINESS.TITLE') }}
            </h1>

            <p
              class="m-0 p-0 text-sm text-slate-600 dark:text-slate-300 text-justify" style="max-width:90%;"
            >
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.CONFIGURE_BUSINESS.DESCRIPTION') }}
            </p>
          </div>

          <div
            class="mx-8 w-1 h-14 bg-green-500 dark:bg-slate-300 rounded-md"
          />

          <div class="flex flex-col" style="flex-basis:45%;">
            <a
              href="https://faq.whatsapp.com/933578044281252"
              target="_blank"
              rel="noopener noreferrer"
              class="no-underline visited:text-slate-700 hover:cursor-pointer rounded-lg border-slate-500 border-2 px-4 py-2 flex items-center gap-x-2 self-end mb-4"
            >
              <p
                class="m-0 p-0 text-sm text-slate-600 dark:text-slate-300 font-semibold visited:text-slate-600 hover:text-green-500"
              >
                {{ $t('INBOX_MGMT.ADD.WHATSAPP.POLICIES.FAQ') }}
              </p>
              <fluent-icon
                icon="link"
                icon-lib="lucide"
                :size="22"
                class="inline cursor-pointer"
              />
            </a>
            <div class="flex items-center gap-2 cursor-pointer">
              <input
                v-model="acceptedWhatsappComercialPolice"
                type="checkbox"
                class="m-0 cursor-pointer"
              />
              <a
                href="https://www.whatsapp.com/legal/commerce-policy/?lang=pt_br"
                target="_blank"
                rel="noopener noreferrer"
                class="m-0 p-0 text-sm text-slate-600 dark:text-slate-300 no-underline visited:text-slate-600 hover:text-green-500"
              >
                {{
                  $t(
                    'INBOX_MGMT.ADD.WHATSAPP.POLICIES.AGREE_WITH_COMERCIAL_POLICY'
                  )
                }}
              </a>
            </div>
            <div class="flex items-center gap-2 cursor-pointer mb-2">
              <input
                v-model="acceptedWhatsappBusinessPolice"
                type="checkbox"
                class="m-0 cursor-pointer"
              />
              <a
                href="https://business.whatsapp.com/policy"
                target="_blank"
                rel="noopener noreferrer"
                class="m-0 p-0 text-sm text-slate-600 dark:text-slate-300 no-underline visited:text-slate-600 hover:text-green-500"
              >
                {{
                  $t(
                    'INBOX_MGMT.ADD.WHATSAPP.POLICIES.AGREE_WITH_BUSINESS_POLICY'
                  )
                }}
              </a>
            </div>

            <div>
              <span
                class="m-0 p-0 font-semibold text-sm text-slate-800 dark:text-slate-100"
              >
                {{ $t('INBOX_MGMT.ADD.WHATSAPP.ATTENTION_VERIFICATION_CODE') }}
              </span>
              <span class="p-0 m-0 text-sm text-slate-600 dark:text-slate-300">
                {{ $t('INBOX_MGMT.ADD.WHATSAPP.SAVE_VERIFICATION_CODE') }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <hr class="m-8" />

      <div class="flex flex-col gap-y-10">
        <div style="max-width:55%">
          <h2
            class="text-xl text-slate-800 dark:text-slate-100 mb-4 text-justify"
          >
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.CONFIGURE_WITH_FACEBOOK.TITLE') }}
          </h2>

          <p
            class="m-0 p-0 text-sm text-slate-600 dark:text-slate-300 text-justify"
            style="max-width: 90%"
          >
            {{
              $t('INBOX_MGMT.ADD.WHATSAPP.CONFIGURE_WITH_FACEBOOK.DESCRIPTION')
            }}
          </p>
        </div>

        <button
          class="join-with-facebook"
          :class="
            hasAcceptedPolicies
              ? 'join-with-facebook-enabled'
              : 'join-with-facebook-disabled'
          "
          @click="startLogin"
        >
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.LOGIN_WITH_FACEBOOK') }}
        </button>
      </div>
    </div>

    <div v-else>
      <div v-if="hasError" class="max-w-lg mx-auto text-center">
        <h5>{{ errorStateMessage }}</h5>
        <p
          v-if="errorStateDescription"
          v-dompurify-html="errorStateDescription"
        />
      </div>
    </div>

    <woot-modal
      v-model:show="showNumberModal"
      size="medium"
      @close="showNumberModal = false"
    >
      <woot-modal-header
        :header-title="$t('INBOX_MGMT.ADD.WHATSAPP.SELECT_PHONE_NUMBER.TITLE')"
        :header-content="
          $t('INBOX_MGMT.ADD.WHATSAPP.SELECT_PHONE_NUMBER.DESCRIPTION')
        "
      />
      <div
        v-for="phoneNumber in phoneNumbers"
        :key="phoneNumber.id"
        class="m-4 pl-4"
      >
        <label class="flex items-center gap-1 cursor-pointer">
          <input
            v-model="selectedNumberId"
            type="radio"
            name="whatsappNumbers"
            :value="phoneNumber.id"
          />
          <span>
            {{ phoneNumber.display_phone_number }} -
            {{
              $t(
                `INBOX_MGMT.ADD.WHATSAPP.SELECT_PHONE_NUMBER.${phoneNumber.code_verification_status}`
              )
            }}
          </span>
        </label>
      </div>
      <div class="flex flex-row justify-end gap-2 py-4 px-6 w-full">
        <woot-button variant="clear" @click="showNumberModal = false">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.SELECT_PHONE_NUMBER.CANCEL') }}
        </woot-button>
        <woot-button :disabled="disableConfirm" @click="onConfirmNumber">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.SELECT_PHONE_NUMBER.CONFIRM') }}
        </woot-button>
      </div>
    </woot-modal>
  </div>
</template>

<style lang="scss" scoped>
.join-with-facebook {
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  width: 240px;
  padding: 0 12px;
  font-family: Helvetica, Arial, sans-serif;
}

.join-with-facebook-enabled {
  cursor: pointer;
  background-color: rgb(24, 119, 242);
  color: #fff;
}

.join-with-facebook-disabled {
  background-color: rgb(225, 222, 222);
  color: slategray;
}
</style>
