/* global axios */
import ApiClient from './ApiClient';

export class WhatsappBusinessAPI extends ApiClient {
  constructor() {
    super('whatsapp_business', { accountScoped: true });
  }

  fetch_phone_numbers(code) {
    return axios.get(`${this.url}/fetch_phone_numbers`, {
      params: { code: code },
    });
  }
}

export default new WhatsappBusinessAPI();
